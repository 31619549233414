import * as React from "react"
import {graphql, Link, withPrefix} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../components/PageHeaderBlock";
import {Col, Container, Row} from "react-bootstrap";

export default function NotFoundPage() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={`404: ${t('pageNotFound')}`} description={t('post3Description')} />
            <PageHeaderBlock h1={`404: ${t('pageNotFound')}`} />
            <Container fluid className="page-404 px-flex">
                <Row>
                    <Col md={6} className="py-3 py-xl-5 d-flex align-items-center justify-content-center">
                        <svg className="svg-icon mt-3 mt-md-0 mb-md-3 mb-xl-0">
                            <use xlinkHref={withPrefix(`sprite.svg#fail-icon`)}></use>
                        </svg>
                    </Col>
                    <Col md={6} className="py-3 py-xl-5 d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-wrap">
                            <div className="w-100 mb-3 mb-xl-4 pb-1 pb-xl-2">{t('pageNotFoundText')}</div>
                            <Link to={'/'} className="btn__big text-center ms-auto ms-xl-0 me-auto me-xl-0">
                                {t('homePageWord')}
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
